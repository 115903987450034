export const environment = {
  production: true,
  baseUrl: 'https://tome.gg',
  appVersion: '1.0.1',
  auth: {
    domain: 'tomegg.us.auth0.com',
    clientId: '78DumgLGFHqRQJNsCeWJLyh3Scs84B3d',
    cacheLocation: 'localstorage',
  },
  hasura: {
    graphql: 'https://hasura.tome.gg/v1/graphql',
    wss: 'wss://hasura.tome.gg/v1/graphql',
    api: 'https://hasura.tome.gg/v1/*'
  }
};
